import React from 'react';
import magventure from "../../../assets/images/landing/store1.jpg";
import { useEffect } from "react";
import Animation from "../Animation";

const MagStore = () => {

    useEffect(() => {
        document.title = "MAG Store - Unlock Your Apple Adventure";
    }, []);

    return (
        <>
            <Animation />
            <div className="page-header media">
                <div>
                    <h1>MAG STORE</h1>
                    <p>UNLOCK YOUR APPLE ADVENTURE</p>
                </div>
            </div>
            <div className="custom-content pt-5">
                <div class="container">
                    <div class="row">

                        <div class="col-md-12">
                            <p>
                                Mag Store, a flagship project under MAG VENTURES, seamlessly combines the
                                convenience of e-commerce with the excitement of physical stores. Specializing in a
                                wide range of Apple products, Mag Store operates with the potential to establish an
                                affiliation with Apple or utilize drop shipping, positioning itself as an authorized retailer.
                            </p>
                            <div className="row align-items-center">
                                <div className="col-md-7">
                                    <ul className="media-content-list mt-4 mb-4">
                                        <li>
                                            <i className="ri-checkbox-circle-fill"></i>
                                            <h3>Product Range:</h3>
                                            <p>
                                                Mag Store is your go-to destination for the latest Apple
                                                products, spanning from cutting-edge iPhones to powerful MacBooks and
                                                versatile iPads. The commitment is to bring the best in technology to customers,
                                                ensuring they stay at the forefront of innovation.

                                            </p>
                                        </li>
                                        <li>
                                            <i className="ri-checkbox-circle-fill"></i>
                                            <h3>Online Triumphs:</h3>
                                            <p>
                                                Currently thriving in the digital realm, Mag Store is an online
                                                haven for tech enthusiasts. With a user-friendly interface and a comprehensive
                                                catalog, customers can explore and purchase their favorite Apple gadgets with
                                                ease.
                                            </p>
                                        </li>
                                        <li>
                                            <i className="ri-checkbox-circle-fill"></i>
                                            <h3> Future Ventures: </h3>
                                            <p>
                                                In the visionary stride towards 2024, Mag Store is set to
                                                elevate the customer experience by introducing physical stores. This expansion
                                                aims to seamlessly blend the best of both online and offline shopping realms,
                                                providing a holistic and immersive adventure for tech enthusiasts.

                                            </p>
                                        </li>
                                        <li>
                                            <i className="ri-checkbox-circle-fill"></i>
                                            <h3>Link to Online Store: </h3>
                                            <p>
                                                Explore the digital aisles of Mag Store.
                                            </p>
                                        </li>


                                    </ul>
                                </div>
                                <div className="col-md-5">
                                    <img src={magventure} className="img-fluid rounded-1" alt="" />
                                </div>
                            </div>

                            <p>
                                Mag Store is not just a retail space; it's an invitation to embark on an Apple adventure,
                                where the latest in technology awaits.
                            </p>


                        </div>

                    </div>
                </div>


            </div>

        </>
    )
}

export default MagStore;

import React from 'react';
import magventure from "../../../assets/images/landing/motors.png";
import { useEffect } from "react";
import Animation from "../Animation";

const MagMotors = () => {

    useEffect(() => {
        document.title = "MAG Motors - Unleash Your Driving Passion";
    }, []);


    return (
        <>
            <Animation />
            <div className="page-header media">
                <div>
                    <h1>MAG MOTORS</h1>
                    <p>UNLEASH YOUR DRIVING PASSION</p>
                </div>
            </div>
            <div className="custom-content pt-5">
                <div class="container">
                    <div class="row">

                        <div class="col-md-12">
                            <p>
                                Mag Motors, a flagship entity under MAG VENTURES, is more than just a dealership
                                for cars and auto parts. It's a destination for individuals passionate about the thrill of the
                                open road, offering a wide selection of high-quality vehicles, both new and pre-owned.
                            </p>
                            <div className="row align-items-center">
                                <div className="col-md-7">
                                    <ul className="media-content-list mt-4 mb-4">
                                        <li>
                                            <i className="ri-checkbox-circle-fill"></i>
                                            <h3>Vehicle Selection:</h3>
                                            <p>
                                                Mag Motors curates a diverse range of vehicles, from
                                                stylish sedans to robust SUVs, ensuring there's something for every driving
                                                preference. The emphasis is on quality, performance, and cutting-edge design.
                                            </p>
                                        </li>
                                        <li>
                                            <i className="ri-checkbox-circle-fill"></i>
                                            <h3>Service Excellence:</h3>
                                            <p>
                                                Beyond sales, Mag Motors places a strong emphasis on
                                                top-notch service and maintenance. Customers can expect comprehensive
                                                support to keep their vehicles running smoothly, adding value to their ownership
                                                experience.
                                            </p>
                                        </li>
                                        <li>
                                            <i className="ri-checkbox-circle-fill"></i>
                                            <h3>Driving Experience:</h3>
                                            <p>
                                                Mag Motors is not just about selling cars; it's about
                                                delivering a driving experience. Whether it's the latest model or a pre-owned
                                                gem, each vehicle is chosen to provide a balance of comfort, style, and
                                                performance.
                                            </p>
                                        </li>
                                        <li>
                                            <i className="ri-checkbox-circle-fill"></i>
                                            <h3>Passionate Community: </h3>
                                            <p>
                                                Mag Motors fosters a community of passionate
                                                drivers. Events, test drives, and exclusive offers create an environment where
                                                customers feel a connection not just with their vehicles but with a shared love for
                                                driving.
                                            </p>
                                        </li>


                                    </ul>
                                </div>
                                <div className="col-md-5">
                                    <img src={magventure} className="img-fluid rounded-1" alt="" />
                                </div>
                            </div>

                            <p>
                                Mag Motors invites individuals to unleash their driving passion, promising not just a
                                vehicle but an exhilarating journey on the open road.
                            </p>


                        </div>

                    </div>
                </div>


            </div>

        </>
    )
}

export default MagMotors;

import React, { useEffect } from "react";
import Animation from "../Animation";
import AOS from "aos";
import "aos/dist/aos.css";


import FoundationImage16 from "../../../assets/images/foundation/foundation (16).jpg";
import FoundationImage17 from "../../../assets/images/foundation/foundation (17).jpg";
import FoundationImage18 from "../../../assets/images/foundation/foundation (18).jpg";
import FoundationImage19 from "../../../assets/images/foundation/foundation (19).jpg";
import FoundationImage20 from "../../../assets/images/foundation/foundation (20).jpg";
import FoundationImage21 from "../../../assets/images/foundation/foundation (21).jpg";
import FoundationImage22 from "../../../assets/images/foundation/foundation (22).jpg";
import FoundationImage23 from "../../../assets/images/foundation/foundation (23).jpg";
import FoundationImage24 from "../../../assets/images/foundation/foundation (24).jpg";
import FoundationImage25 from "../../../assets/images/foundation/foundation (25).jpg";
import FoundationImage26 from "../../../assets/images/foundation/foundation (26).jpg";
import FoundationImage27 from "../../../assets/images/foundation/foundation (27).jpg";


const images = [

    FoundationImage26,
    FoundationImage21,
    FoundationImage22,
    FoundationImage23,
    FoundationImage24,
    FoundationImage25,
    FoundationImage27,
    FoundationImage16,
    FoundationImage17,
    FoundationImage18,
    FoundationImage19,
    FoundationImage20,

];

function Foundation(props) {
    useEffect(() => {
        document.title = "MAG VENTURES";
        AOS.init({
            duration: 1000,
            easing: "ease",
        });
    }, []);
    return (
        <>
            <Animation />
            <div className="page-header foundation">
                <div>
                    <h1>Mag Foundation - Venture of Hope</h1>
                    <p>Investing in Humanity to Build a Better World</p>
                </div>
            </div>
            <section className="simple-section">
                <div className="container">
                    <h3 className="section-title mb-4"
                        data-aos="fade-up"
                        data-aos-delay="300"
                        data-aos-easing="ease-in-out"
                    >ABOUT US</h3>
                    <p
                        data-aos="fade-up"
                        data-aos-delay="400"
                        data-aos-easing="ease-in-out">
                        <strong>Mag Foundation is an integral project of MAG VENTURES</strong>, dedicated to making a positive
                        impact through charitable initiatives. At Mag Foundation, we believe in the power of compassion
                        and collective action. Our dedicated team is driven by the desire to make a lasting difference in
                        the lives of those less fortunate. We believe in giving back to the community and creating a
                        better world for everyone.
                    </p>
                    <div className="row">
                        <div className="col-md-8">
                            <div className="box-mv"
                                data-aos="flip-left"
                                data-aos-delay="500"
                                data-aos-easing="ease-in-out"
                            >
                                <div className="section-title mb-4">MISSION</div>
                                <p>Our mission at Mag Foundation is to uplift and empower individuals and communities in need.
                                    Through our charitable programs and partnerships, we aim to provide essential resources,
                                    support education, promote healthcare, and foster sustainable development. We also believe that
                                    everyone deserves access to nutritious food. Through our food charity initiatives, we work
                                    tirelessly to provide meals and support to those facing food insecurity. <br /><br />Our goal is to not only
                                    alleviate hunger but also promote long-term food security by advocating for sustainable
                                    agricultural practices and empowering communities to grow their own food. <br /><br />By addressing this
                                    basic need, we aim to create a strong foundation for individuals to thrive and build brighter
                                    futures. Together, we can make a difference in the lives of those who need it most.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="box-mv"
                                data-aos="flip-right"
                                data-aos-delay="600"
                                data-aos-easing="ease-in-out"
                            >

                                <div className="section-title mb-4">VISION</div>
                                <p>Our vision for Mag Foundation is to create a society where everyone has equal opportunities and
                                    access to basic necessities regardless of their circumstances. Our envision is to address pressing
                                    social issues and alleviate poverty. Through our unwavering dedication and strategic initiatives,
                                    we strive to inspire others to join us in making a difference and build a brighter future together.</p>

                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <section className="simple-section pt-0">
                <div className="container">
                    <div className="section-title mb-4">Foundation</div>
                    <div className="row g-3">
                        <div className="col-md-6 offset-md-3">
                            <div className="blog-item"
                                data-aos="fade-up"
                                data-aos-delay="100"
                                data-aos-easing="ease-in-out"
                            >
                                <a href='https://youtu.be/cKv9_WrwZUU?si=XTKPA5QAmrzrCIKo'>
                                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/cKv9_WrwZUU?si=zX6GLd9NyAUnwboX" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                </a>
                                <span>25 <br /> JAN</span>
                                <div className="content">
                                    <h3>Honoring Muharram: MAG Foundation's Special Arrangements and Community Langar</h3>
                                    <h6>Venture Of Success</h6>

                                    <a href='https://youtu.be/cKv9_WrwZUU?si=XTKPA5QAmrzrCIKo'>READ MORE</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                        </div>
                        {images.map((image, index) => (
                            <div className="col-md-4" key={index}>
                                <img src={image} alt={`Foundation ${index + 1}`} className="img-fluid rounded" />
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    );
}

export default Foundation;
import React, { useEffect, useState } from 'react'
import logo from '../../assets/images/logo/magholding.svg';

const Animation = () => {

    const [showOverlay, setShowOverlay] = useState(true);


    useEffect(() => {
        // Hide the overlay after 5 seconds
        const timeoutId = setTimeout(() => {
            setShowOverlay(false);
        }, 30000);

        // Clean up the timeout when the component is unmounted
        return () => {
            clearTimeout(timeoutId);
        };
    }, []);
    return (
        <>
            {showOverlay && (
                <div id="overlay">
                    <div id="loading-items">
                        <a className="logo-loading" title="MAG Ventures">
                            <img src={logo} alt="MAG Ventures Logo" id="logo" />
                        </a>
                    </div>
                </div>
            )}
        </>
    )
}

export default Animation
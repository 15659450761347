import React from 'react';
import magventure from "../../../assets/images/landing/market.png";
import { useEffect } from "react";
import Animation from "../Animation";

const MagMarket = () => {

    useEffect(() => {
        document.title = "MAG Super Market - Your One-Stop Shop for all Your Needs";
    }, []);
    return (
        <>
            <Animation />
            <div className="page-header media">
                <div>
                    <h1>MAG SUPER MARKET</h1>
                    <p>YOUR ONE-STOP SHOP FOR ALL YOUR NEEDS</p>
                </div>
            </div>
            <div className="custom-content pt-5">
                <div class="container">
                    <div class="row">

                        <div class="col-md-12">
                            <p>
                                Mag SuperMarket, an integral part of MAG VENTURES, is committed to providing
                                customers with a seamless shopping experience. The supermarket offers a diverse
                                range of products, including fresh produce, groceries, and household items, all
                                conveniently available under one roof.
                            </p>
                            <div className="row align-items-center">
                                <div className="col-md-7">
                                    <ul className="media-content-list mt-4 mb-4">
                                        <li>
                                            <i className="ri-checkbox-circle-fill"></i>
                                            <h3> Wide Variety:</h3>
                                            <p>
                                                Mag Super Market boasts a comprehensive selection, ensuring
                                                customers can find everything they need, from daily essentials to specialty items.
                                                The aim is to cater to diverse preferences and offer a one-stop shopping
                                                destination.

                                            </p>
                                        </li>
                                        <li>
                                            <i className="ri-checkbox-circle-fill"></i>
                                            <h3>Competitive Prices:</h3>
                                            <p>
                                                With a focus on customer satisfaction, Mag Super Market
                                                maintains competitive prices without compromising on quality. This commitment
                                                aims to make shopping both economical and enjoyable for every customer.
                                            </p>
                                        </li>
                                        <li>
                                            <i className="ri-checkbox-circle-fill"></i>
                                            <h3>Customer Service Excellence: </h3>
                                            <p>
                                                Beyond products and prices, Mag Super
                                                Market prides itself on providing excellent customer service. Friendly staff and
                                                efficient service contribute to a positive and stress-free shopping experience.
                                            </p>
                                        </li>
                                        <li>
                                            <i className="ri-checkbox-circle-fill"></i>
                                            <h3>Convenience Redefined: </h3>
                                            <p>
                                                Mag Super Market's layout and organization are
                                                designed for convenience. Navigating through aisles is effortless, and the store
                                                layout ensures a hassle-free and enjoyable shopping journey.
                                            </p>
                                        </li>


                                    </ul>
                                </div>
                                <div className="col-md-5">
                                    <img src={magventure} className="img-fluid rounded-1" alt="" />
                                </div>
                            </div>

                            <p>
                                Mag Super Market is not just a grocery store; it's a commitment to redefining
                                convenience and delivering a shopping experience that exceeds expectations.
                            </p>


                        </div>

                    </div>
                </div>


            </div>

        </>
    )
}

export default MagMarket;

import React from 'react';
import magventure from "../../../assets/images/landing/magventure.jpg";
import Animation from "../Animation";

const MagVentures = () => {
    return (
        <>
          <Animation />
            <div className="page-header media">
                <div>
                    <h1>MAG VENTURES</h1>
                    <p>VENTURE OF SUCCESS</p>
                </div>
            </div>
            <div className="custom-content pt-5">
                <div class="container">
                    <div class="row">

                        <div class="col-md-12">
                            <p>
                                MAG VENTURES serves as the dynamic engine propelling its diverse portfolio into
                                every facet of daily life. This multifaceted entity orchestrates a symphony of projects,
                                from the efficiency of drop shipping to the convenience of Mag Supermarket and the
                                sophistication of Mag Motors cars. It's more than a brand; it's a promise of innovation
                                and quality embedded in every brick erected by Mag Builders & Developers and every
                                delectable bite served at Mag's Cafe.

                            </p>
                            <div className="row align-items-center">
                                <div className="col-md-7">
                                    <ul className="media-content-list mt-4 mb-4">
                                        <li>
                                            <i className="ri-checkbox-circle-fill"></i>
                                            <h3>Drop Shipping Excellence</h3>
                                            <p>
                                                MAG VENTURES takes pride in spearheading
                                                the exclusive dropshipping and wholesale of Apple products. This ensures
                                                customers receive the latest in tech directly from the source, reflecting a
                                                commitment to staying at the forefront of innovation.

                                            </p>
                                        </li>
                                        <li>
                                            <i className="ri-checkbox-circle-fill"></i>
                                            <h3>Diverse Portfolio:</h3>
                                            <p>
                                                MAG VENTURES' portfolio extends beyond tech, reaching
                                                into the real estate domain with Mag Builders & Developers. Here, a team of
                                                experts specializes in crafting exceptional structures, standing as a testament to
                                                top-notch quality and enduring craftsmanship
                                            </p>
                                        </li>
                                        <li>
                                            <i className="ri-checkbox-circle-fill"></i>
                                            <h3>Retail Prowess:</h3>
                                            <p>
                                                From the seamless aisles of Mag Supermarket, offering a
                                                comprehensive array of fresh produce, groceries, and household items, to the
                                                inviting ambiance of Mag's Cafe, MAG VENTURES ensures a convenient and
                                                enjoyable shopping experience for all.

                                            </p>
                                        </li>
                                        <li>
                                            <i className="ri-checkbox-circle-fill"></i>
                                            <h3>Automotive Passion: </h3>
                                            <p>
                                                Mag Motors, under the umbrella of MAG VENTURES,
                                                transcends being merely a purveyor of cars and auto parts. It's an embodiment of
                                                the thrill of the open road, offering a wide selection of high-quality vehicles, both
                                                new and pre-owned, complemented by top-notch service and maintenance.
                                            </p>
                                        </li>
                                        <li>
                                            <i className="ri-checkbox-circle-fill"></i>
                                            <h3>Elegance Redefined:</h3>
                                            <p>
                                                Eleganz by Mag, another facet of MAG VENTURES,
                                                converges beauty and fashion, introducing a world of endless possibilities. From
                                                trendy clothing to luxurious beauty products, Eleganz by Mag curates the latest
                                                trends, allowing individuals to express their unique style and embrace their inner
                                                fashionista.

                                            </p>
                                        </li>

                                    </ul>
                                </div>
                                <div className="col-md-5">
                                    <img src={magventure} className="img-fluid rounded-1" alt="" />
                                </div>
                            </div>

                            <p>
                                In essence, MAG VENTURES delivers a world of possibilities, all underscored by an
                                unwavering commitment to excellence.
                            </p>


                        </div>

                    </div>
                </div>


            </div>

        </>
    )
}

export default MagVentures;

import React from 'react';
import magventure from "../../../assets/images/landing/cafe.png";
import { useEffect } from "react";
import Animation from "../Animation";

const MagCafe = () => {

    useEffect(() => {
        document.title = "MAG's Cafe - TASTE THE MAGIC";
    }, []);


    return (
        <>
            <Animation />
            <div className="page-header media">
                <div>
                    <h1>MAG'S CAFE</h1>
                    <p>TASTE THE MAGIC</p>
                </div>
            </div>
            <div className="custom-content pt-5">
                <div class="container">
                    <div class="row">

                        <div class="col-md-12">
                            <p>
                                Mag's Cafe, a charming haven under MAG VENTURES, invites patrons to relax and
                                indulge in a delightful culinary experience. Nestled in a cozy and inviting space, the cafe
                                offers a diverse menu catering to various tastes and preferences.

                            </p>
                            <div className="row align-items-center">
                                <div className="col-md-7">
                                    <ul className="media-content-list mt-4 mb-4">
                                        <li>
                                            <i className="ri-checkbox-circle-fill"></i>
                                            <h3>Aromatic Delights:</h3>
                                            <p>
                                                Mag's Cafe is synonymous with aromatic coffees and an
                                                array of flavorful beverages. From freshly brewed coffee to specialty blends, the
                                                menu is crafted to satisfy even the most discerning coffee enthusiasts.
                                            </p>
                                        </li>
                                        <li>
                                            <i className="ri-checkbox-circle-fill"></i>
                                            <h3>Delectable Treats: </h3>
                                            <p>
                                                The menu extends beyond beverages to a selection of
                                                mouth-watering pastries and treats. Each item is meticulously prepared to ensure
                                                a perfect balance of flavors, leaving patrons craving for more
                                            </p>
                                        </li>
                                        <li>
                                            <i className="ri-checkbox-circle-fill"></i>
                                            <h3> Ambiance:</h3>
                                            <p>
                                                Mag's Cafe goes beyond being a culinary destination; it's an
                                                experience. The ambiance is carefully curated, providing the perfect setting for
                                                friends to meet, or individuals to enjoy some quiet time in a welcoming
                                                atmosphere.
                                            </p>
                                        </li>
                                        <li>
                                            <i className="ri-checkbox-circle-fill"></i>
                                            <h3>Unwinding Space: </h3>
                                            <p>
                                                Whether it's a business meeting or a leisurely afternoon,
                                                Mag's Cafe offers a space to unwind. The combination of exceptional food,
                                                soothing ambiance, and attentive service creates an environment that transcends
                                                the ordinary.
                                            </p>
                                        </li>

                                    </ul>
                                </div>
                                <div className="col-md-5">
                                    <img src={magventure} className="img-fluid rounded-1" alt="" />
                                </div>
                            </div>

                            <p>
                                Mag's Cafe is not just about the magic in its name; it's about creating moments of joy
                                and satisfaction through the art of culinary delights.
                            </p>


                        </div>

                    </div>
                </div>


            </div>

        </>
    )
}

export default MagCafe;

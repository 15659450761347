import React from 'react';
import magventure from "../../../assets/images/landing/construction.jpg";
import { useEffect } from "react";
import Animation from "../Animation";

const MagBuilders = () => {

    useEffect(() => {
        document.title = "MAG Builders and Developers - Build Your Dreams";
    }, []);


    return (
        <>
            <Animation />
            <div className="page-header media">
                <div>
                    <h1>MAG BUILDERS & DEVELOPERS</h1>
                    <p>BUILD YOUR DREAMS</p>
                </div>
            </div>
            <div className="custom-content pt-5">
                <div class="container">
                    <div class="row">

                        <div class="col-md-12">
                            <p>
                                Mag Builders & Developers, a cornerstone of MAG VENTURES, is dedicated to
                                building a solid foundation for the future. Specializing in creating exceptional structures
                                that withstand the test of time, this sector covers a spectrum from residential complexes
                                to commercial buildings.
                            </p>
                            <div className="row align-items-center">
                                <div className="col-md-7">
                                    <ul className="media-content-list mt-4 mb-4">
                                        <li>
                                            <i className="ri-checkbox-circle-fill"></i>
                                            <h3>Expert Craftsmanship:</h3>
                                            <p>
                                                At the heart of Mag Builders & Developers lies a team
                                                of experts committed to delivering top-notch quality and unparalleled
                                                craftsmanship. Every project undertaken is a testament to precision and
                                                excellence.

                                            </p>
                                        </li>
                                        <li>
                                            <i className="ri-checkbox-circle-fill"></i>
                                            <h3> Residential Marvels:</h3>
                                            <p>
                                                Mag Builders & Developers takes pride in crafting
                                                residential complexes that go beyond brick and mortar. These spaces are
                                                designed to provide not just shelter but a home where families can thrive.
                                            </p>
                                        </li>
                                        <li>
                                            <i className="ri-checkbox-circle-fill"></i>
                                            <h3> Commercial Excellence:</h3>
                                            <p>
                                                Extending beyond homes, Mag Builders &
                                                Developers ventures into the commercial realm. Whether it's creating office
                                                spaces, retail establishments, or mixed-use developments, the focus remains on
                                                excellence in design and functionality.

                                            </p>
                                        </li>
                                        <li>
                                            <i className="ri-checkbox-circle-fill"></i>
                                            <h3>Future Visions: </h3>
                                            <p>
                                                With an eye on the future, Mag Builders & Developers
                                                envisions and creates spaces that harmonize with the evolving needs of
                                                communities. The commitment is not just to build structures but to shape
                                                environments.

                                            </p>
                                        </li>


                                    </ul>
                                </div>
                                <div className="col-md-5">
                                    <img src={magventure} className="img-fluid rounded-1" alt="" />
                                </div>
                            </div>

                            <p>
                                Mag Builders & Developers is where dreams find a solid foundation, and visions of
                                exceptional living and working spaces come to life.

                            </p>


                        </div>

                    </div>
                </div>


            </div>

        </>
    )
}

export default MagBuilders;

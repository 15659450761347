import React from 'react'
import banner from '../../../assets/images/page-heading-bg.png'

const PageHeading = (props) => {
    return (
        <div className='page-heading-banner'>
            <img src={banner} />
            <h3>{props.Content}</h3>
        </div>
    )
}

export default PageHeading
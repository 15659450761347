import React from 'react';
import magventure from "../../../assets/images/landing/fashion.png";
import { useEffect } from "react";
import Animation from "../Animation";

const MagEleganz = () => {

    useEffect(() => {
        document.title = "ELEGANZ BY MAG - EMBRACE YOUR INNER ELEGANCE";
    }, []);


    return (
        <>
            <Animation />
            <div className="page-header media">
                <div>
                    <h1>ELEGANZ BY MAG</h1>
                    <p>EMBRACE YOUR INNER ELEGANCE</p>
                </div>
            </div>
            <div className="custom-content pt-5">
                <div class="container">
                    <div class="row">

                        <div class="col-md-12">
                            <p>
                                Eleganz by Mag represents the intersection of beauty and fashion, curating a world of
                                endless possibilities for individuals looking to express their unique style. From trendy
                                clothing to luxurious beauty products, this segment under MAG VENTURES offers a
                                platform to unleash your glam and elegance.
                            </p>
                            <div className="row align-items-center">
                                <div className="col-md-7">
                                    <ul className="media-content-list mt-4 mb-4">
                                        <li>
                                            <i className="ri-checkbox-circle-fill"></i>
                                            <h3>Drop Shipping Excellence</h3>
                                            <p>
                                                Fashion Curation: Eleganz by Mag constantly curates the latest fashion
                                                trends, offering a diverse and ever-evolving collection. From chic clothing to
                                                accessories, each item is selected to inspire and elevate personal style.
                                            </p>
                                        </li>
                                        <li>
                                            <i className="ri-checkbox-circle-fill"></i>
                                            <h3>Diverse Portfolio:</h3>
                                            <p>
                                                Luxurious Beauty: The beauty segment of Eleganz by Mag features a range
                                                of luxurious products, from skincare essentials to makeup items. The emphasis is
                                                on quality and efficacy, helping individuals enhance their natural beauty.
                                            </p>
                                        </li>
                                        <li>
                                            <i className="ri-checkbox-circle-fill"></i>
                                            <h3>Retail Prowess:</h3>
                                            <p>
                                                Trendsetting Collections: Eleganz by Mag goes beyond being a fashion
                                                outlet; it's a trendsetter. The collections are designed to reflect the latest in
                                                fashion and beauty, allowing individuals to stay ahead in the world of style.
                                            </p>
                                        </li>
                                        <li>
                                            <i className="ri-checkbox-circle-fill"></i>
                                            <h3>Automotive Passion: </h3>
                                            <p>
                                                Express Your Style: More than a brand, Eleganz by Mag encourages
                                                individuals to embrace their inner elegance. It's a platform where fashion
                                                becomes a form of self-expression, and beauty is celebrated in all its diverse
                                                forms.
                                            </p>
                                        </li>


                                    </ul>
                                </div>
                                <div className="col-md-5">
                                    <img src={magventure} className="img-fluid rounded-1" alt="" />
                                </div>
                            </div>

                            <p>
                                Eleganz by Mag is not just a fashion and beauty destination; it's an invitation to explore,
                                embrace, and express your unique elegance.
                            </p>


                        </div>

                    </div>
                </div>


            </div>

        </>
    )
}

export default MagEleganz;

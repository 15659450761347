import React from 'react'
import logo from '../../../assets/images/logo/magholding-whtie.svg'
import Animation from '../Animation'

const CommingSoon = () => {
  return (
    <>
      <Animation />
      <div className='simple-section comming-soon'>
        <img src={logo} />
        <h1>Comming Soon...</h1>
      </div>
    </>
  )
}

export default CommingSoon